import { faEye, faEyeSlash, faScrewdriverWrench } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import './NewSettingsPage.css'
import FaqQuestionsArray from '../../FAQ_Json.json'
import MDSpinner from 'react-md-spinner'
import ConvertMountainTimeToLocal from '../../Functions/ConvertMountainTimeToLocal'

export default class NewSettingsPage extends Component {
  constructor() {
    super()
    this.state = {
      faqQuestions: [],
      pageDisplayed: 'Settings',
      userFeedback: '',
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      changePasswordLoading: false,
      submitFeedbackLoading: false,
      lastFourDigits: ''
    }
  }

  componentDidMount() {
    // console.log(this.props)
    this.updateFaqQuestionsHandler()
  }

  updateFaqQuestionsHandler = () => {
		let questions = []
		let answers = []
		FaqQuestionsArray.forEach((value, index) => {
			let beginning = value.slice(0, 10)
			if (beginning.includes('question:')) {
				let question = value.slice(-value.length + 10)
				questions.push(question)
			} else if (beginning.includes('answer:')) {
				let answer = value.slice(-value.length + 8)
				answers.push(answer)
			}
		})
		let FAQ = []
		questions.forEach((value, index) => {
			FAQ.push({
				question: value,
				answer: answers[index]
			})
		})
		this.setState({faqQuestions: FAQ})
	}

  getSubscriptionDaysRemaining = () => {
    let expirationDate = new Date(this.props.userData.LicenseExpiration)
    let today = new Date()
    let daysRemaining = Math.floor((expirationDate - today) / (1000 * 60 * 60 * 24))
    return daysRemaining
  }

  formatDateString = (dateString) => {
    let expirationDate = new Date(ConvertMountainTimeToLocal(dateString, this.props.userData.TimeZone))
    const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    let month = expirationDate.getMonth() + 1
    let day = expirationDate.getDate()
    let year = expirationDate.getFullYear()
    let monthName = MONTHS[month - 1]
    return `${monthName} ${day}, ${year}`
  }

  formatTimeString = (dateString) => {
    let expirationDate = new Date(ConvertMountainTimeToLocal(dateString, this.props.userData.TimeZone))
    let hours = expirationDate.getHours()
    let minutes = expirationDate.getMinutes()
    let ampm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours ? hours : 12
    minutes = minutes < 10 ? '0' + minutes : minutes
    let strTime = hours + ':' + minutes + ' ' + ampm
    return strTime
  }

  updateTimeZone = (e) => {
    let value = e.target.value
    this.setState({updateTimeZoneLoading: true}, async () => {
        await this.props.updateSelectedTimeZone(value)
        this.setState({updateTimeZoneLoading: false})
    })
  }

  fetchChangePassword = () => {
    if (this.state.newPassword !== this.state.confirmNewPassword) {
      return this.props.displayErrorPopupHandler('Passwords do not match', 'Please make sure your new password and confirm new password fields match.')
		}

    this.setState({changePasswordLoading: true})
    console.log(this.props.userData, this.state.currentPassword, this.state.newPassword)
    fetch(`${this.props.route}/changepassword.webapi`, {
      method: 'POST',
      headers: {
        'Token': this.props.userData.Token,
        'Content-Type': 'text/plain',
      },
      body: JSON.stringify({
        customerId: this.props.userData.CustomerId,
        oldPassword: this.state.currentPassword,
        newPassword: this.state.newPassword,
      })
    })
    .then(response => {
      this.setState({changePasswordLoading: false})
      if (response.status === 401) {
        // show authentication popup
        this.props.setAuthenticationError()
      } else {
        return response.text()
      }
    })
    .then(response => {
      console.log(response)
      if (response.includes('Password updated')) {
        this.setState({
          pageDisplayed: "Settings",
          currentPassword: "",
          newPassword: "",
          confirmNewPassword: ""
        }, () => {
          this.props.displaySuccessPopupHandler('Password Changed Successfully', 'Your password has been updated successfully.')
        })
      } else {
        // Remove curly braces from message
        let result = response.replace('{', '')
        result = result.replace('}', '')
        this.props.displayErrorPopupHandler('Failed To Change Password', result)
      }
    })
    .catch(error => {
      console.log(error)
      this.setState({changePasswordLoading: false}, () => {
        this.props.displayErrorPopupHandler('Failed To Change Password', 'There was an error changing your password. Please try again later.')
      })
    })
  }

  fetchSubmitFeedback = () => {
    this.setState({submitFeedbackLoading: true})
    fetch(`${this.props.route}/feedback.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.props.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				CustomerId: this.props.userData.CustomerId,
				feedback: this.state.userFeedback
			})
		})
		.then((response) => {
			if (response.status === 401) {
				this.setState({authenticationError: true})
				throw new Error('Authentication Error')
			} else {
				return response.text()
			}
		})
		.then((response) => {
      console.log(response)
      this.setState({
        submitFeedbackLoading: false,
        pageDisplayed: 'Settings',
        userFeedback: '',
      }, () => {
        this.props.displaySuccessPopupHandler('Feedback Submitted Successfully', 'Thanks for your feedback, we will review it and get back to you if necessary.')
      })
		})
		.catch((error) => {
      console.log(error)
      this.setState({
        submitFeedbackLoading: false,
      }, () => {
        this.props.displayErrorPopupHandler('Error Submitting Feedback', 'There was an error submitting your feedback. Please try again.')
      })
		})
  }

  handlePrintReceipt = () => {
    let confirmationPopupOptions = {
      title: `Print Your Receipt`,
      description: `If you need the last four digits of your card added to your receipt, please enter them here.`,
      confirmFunction: async () => {
        this.props.setPopupLoading(true)
        await this.fetchPrintReceipt()
        this.props.setPopupLoading(false)
      },
      children: (
        <div className='printReceiptInputContainer'>
          <label for="printReceiptInput" className='printReceiptInputLabel standardLabel'>Last Four Digits of Card</label>
          <input className='settingsPageInput confirmationPopupInput' id="printReceiptInput" type='text' placeholder='Last 4' maxLength={4} onChange={(e) => {this.setState({lastFourDigits: e.target.value})}}/>
        </div>
      ),
      closePopup: () => {
        this.props.closeConfirmationPopup()
      }
    }
    this.props.displayConfirmationPopupHandler(confirmationPopupOptions)
  }

  fetchPrintReceipt = async () => {
    if (isNaN(Number(this.state.lastFourDigits))) {
      return window.alert('Invalid Last 4 Entry')
    } else if (this.state.lastFourDigits.length !== 0 && this.state.lastFourDigits.length !== 4) {
      return window.alert('Invalid Last 4 Entry')
    }

    try {
      let printReceiptFetch = await fetch(`${this.props.route}/invoicetopdf.webapi`, {
        method: 'POST',
        headers: {
          'Token': this.props.userData.Token,
          'Content-Type': 'text/plain'
        },
        body: JSON.stringify({
          CustomerId: this.props.userData.CustomerId,
          Last4: this.state.lastFourDigits
        })
      })

      if (!printReceiptFetch.ok) {
        return this.props.displayErrorPopupHandler('Error Printing Receipt', 'There was an error printing your receipt. Please try again.')
      }

      let printReceiptHTML = await printReceiptFetch.text()

      this.props.closeConfirmationPopup()
      this.props.setPrintReceiptHTML(printReceiptHTML)
    } catch(error) {
      this.props.displayErrorPopupHandler('Error Printing Receipt', 'There was an error printing your receipt. Please try again.')
      console.log(error)
    }
  }

  render() {
    if (this.state.pageDisplayed === 'Settings') {
      return (
        <div className='settingsPageWrapper'>
          <div className='settingsPageHeaderWrapper'>
            <FontAwesomeIcon icon={faScrewdriverWrench} className='settingsPageHeaderIcon' />
            <h1 className='settingsPageHeaderText'>Account Settings</h1>
            <button className='settingsPageLogoutButton primaryButton' onClick={this.props.logoutHandler}>Logout</button>
          </div>
          <div className='settingsProfileDetailsWrapper'>
            <h2 className='settingsHeader settingsProfileDetailsHeader'>Profile Details</h2>
            <div className='settingsProfileDetailWrapper'>
              <h3 className='settingsProfileDetailTitle'>Name</h3>
              <p className='settingsProfileDetailText'>{this.props.userData.CustomerFirstName} {this.props.userData.CustomerLastName}</p>
            </div>
            <div className='settingsProfileDetailWrapper'>
              <h3 className='settingsProfileDetailTitle'>Email</h3>
              <p className='settingsProfileDetailText'>{this.props.userData.CustomerUserName}</p>
            </div>
            <div className='settingsProfileDetailWrapper'>
              <h3 className='settingsProfileDetailTitle'>Account Creation Date</h3>
              <p className='settingsProfileDetailText'>{this.formatDateString(this.props.userData.DateCustCreated)} {this.formatTimeString(this.props.userData.DateCustCreated)}</p>
              {/* <p className='settingsProfileDetailText'>June 13th, 2020, 3:21 PM</p> */}
            </div>
            <div className='settingsProfileDetailWrapper'>
              <h3 className='settingsProfileDetailTitle'>Subscription End Date</h3>
              <p className='settingsProfileDetailText'>{this.formatDateString(this.props.userData.LicenseExpiration)} {this.formatTimeString(this.props.userData.LicenseExpiration)}</p>
              {/* <p className='settingsProfileDetailText'>November 4th, 2022 10:00 PM</p> */}
            </div>
          </div>
          <div className='settingsActionsWrapper'>
            <div className='accountSettingsCard' style={{gridArea: 'a'}}>
              <h3 className='accountSettingsCardTitle'>Resubscribe</h3>
              <div>
                <p className='accountSettingsToggleDescription'>You have <strong>{this.getSubscriptionDaysRemaining()} Days</strong> remaining in your subscription ending <strong>{this.formatDateString(this.props.userData.LicenseExpiration)}</strong>. If you need more time to study, you can resubscribe by following these instructions:</p>
                <ol className='accountSettingsOrderedList'>
                  <li className='accountSettingsOrderedListNumber'>1</li>
                  <li className='accountSettingsOrderedListItem'><p>Click “Resubscribe” or navigate to *PsychQB Purchase Link*</p></li>
                  <li className='accountSettingsOrderedListNumber'>2</li>
                  <li className='accountSettingsOrderedListItem'><p>Select the amount of time that you would like to extend your subscription</p></li>
                  <li className='accountSettingsOrderedListNumber'>3</li>
                  <li className='accountSettingsOrderedListItem'><p>At checkout, use the same e-mail address that you used to subscribe originally. The system will automatically locate your account and extend your subscription.</p></li>
                </ol>
              </div>
              <a href="https://www.psychqb.com/purchase" rel="noopener noreferrer" target="_blank">
                  <button className='accountSettingsButton'>Resubscribe</button>
              </a>
            </div>
            <div className='accountSettingsCard' style={{gridArea: 'b'}}>
              <h3 className='accountSettingsCardTitle'>Time Zone</h3>
              <p className='accountSettingsToggleDescription'>Setting your time zone will make device times, like exam completion dates, display in your local time instead of UTC.</p>
              {/* <select className='accountSettingsDropdown' value={this.props.userProfile.selectedTimeZone} onChange={this.updateTimeZone}> */}
              <select className='accountSettingsDropdown' value={this.props.userData.TimeZone === "" ? "-7" : this.props.userData.TimeZone} onChange={this.updateTimeZone}>
                <option value="-12">GMT -12 US Minor Outlying Islands</option>
                <option value="-11">GMT -11 US Minor Outlying Islands</option>
                <option value="-10">GMT -10 Honolulu</option>
                <option value="-9">GMT -9 Alaska Time</option>
                <option value="-8">GMT -8 Pacific Time</option>
                <option value="-7">GMT -7 Mountain Time</option>
                <option value="-6">GMT -6 Central Time</option>
                <option value="-5">GMT -5 Eastern Time</option>
                <option value="-4">GMT -4 Atlantic Time</option>
                <option value="-3">GMT -3 Greenland</option>
                <option value="-2">GMT -2 Brazil</option>
                <option value="-1">GMT -1 Portugal</option>
                <option value="+0">GMT +0 United Kingdom</option>
                <option value="+1">GMT +1 Paris</option>
                <option value="+2">GMT +2 Johannesburg</option>
                <option value="+3">GMT +3 Moscow</option>
                <option value="+4">GMT +4 Dubai</option>
                <option value="+5">GMT +5 Karachi</option>
                <option value="+6">GMT +6 Dhaka</option>
                <option value="+7">GMT +7 Jakarta</option>
                <option value="+8">GMT +8 Shanghai</option>
                <option value="+9">GMT +9 Tokyo</option>
                <option value="+10">GMT +10 Sydney</option>
                <option value="+11">GMT +11 Nouméa</option>
                <option value="+12">GMT +12 Auckland</option>
                <option value="+13">GMT +13 Tonga</option>
                <option value="+14">GMT +14 Kiribati</option>
              </select>
            </div>
            <div className='accountSettingsCard' style={{gridArea: 'c'}}>
              <h3 className='accountSettingsCardTitle'>View FAQ</h3>
              <p className='accountSettingsToggleDescription'>Have a question about our software? We answer those here.</p>
              <button className='accountSettingsButton' onClick={() => this.setState({pageDisplayed: 'Faq'})}>View FAQ</button>
            </div>
            <div className='accountSettingsCard' style={{gridArea: 'd'}}>
              <h3 className='accountSettingsCardTitle'>Report Bug/Feedback</h3>
              <p className='accountSettingsToggleDescription'>Noticed a bug or have something to share with us? Let us know here.</p>
              <button className='accountSettingsButton' onClick={() => this.setState({pageDisplayed: 'ReportBug'})}>Report Bug/Feedback</button>
            </div>
            <div className='accountSettingsCard' style={{gridArea: 'e'}}>
              <h3 className='accountSettingsCardTitle'>Print Receipt</h3>
              <p className='accountSettingsToggleDescription'>Need a copy of your account receipt? You can download a copy or print it here.</p>
              <button className='accountSettingsButton' onClick={() => this.handlePrintReceipt()}>Print Receipt</button>
            </div>
            <div className='accountSettingsCard' style={{gridArea: 'f'}}>
              <h3 className='accountSettingsCardTitle'>Change Password</h3>
              <p className='accountSettingsToggleDescription'>Change the password you use to log in.</p>
              <button className='accountSettingsButton' onClick={() => this.setState({pageDisplayed: 'ChangePassword'})}>Change Password</button>
            </div>
          </div>
        </div>
      )
    } else if (this.state.pageDisplayed === 'Faq') {
      return (
        <div className='settingsPageWrapper'>
          <button className='settingsPageBackButton primaryButton' onClick={() => this.setState({pageDisplayed: 'Settings'})}>Back To Account Settings</button>
          <h1 className='settingsPageTitle'>Frequently Asked Questions</h1>
          <p className='settingsPageSubtitle'>Have a question about our software? We answer those here.</p>
          {this.state.faqQuestions.map((question, index) => (
            <div className='settingsPageCardContainer'>
              <h2 className='settingsPageFaqTitle'>{question.question}</h2>
              <p className='settingsPageFaqBody'>{question.answer}</p>
            </div>
          ))}
        </div>
      )
    } else if (this.state.pageDisplayed === 'ChangePassword') {
      return (
        <div className='settingsPageWrapper'>
          <button className='settingsPageBackButton primaryButton' onClick={() => this.setState({pageDisplayed: 'Settings'})}>Back To Account Settings</button>
          <h1 className='settingsPageTitle'>Change Password</h1>
          <p className='settingsPageSubtitle'>Update the password you use to log in.</p>
          <div className='settingsPageCardContainer'>
            <label className='settingsPageInputLabel' htmlFor="currentPassword">Current Password</label>
            <div className='settingsPageInputWrapper'>
              <input
                className='settingsPageInput'
                id="currentPassword"
                type={this.state.showCurrentPassword ? 'text' : 'password'}
                value={this.state.currentPassword}
                onChange={(e) => this.setState({currentPassword: e.target.value})}
              />
              <button className='settingsPageInputShowButton' onClick={() => this.setState({showCurrentPassword: !this.state.showCurrentPassword})}>
                <FontAwesomeIcon icon={this.state.showCurrentPassword ? faEyeSlash : faEye} />
              </button>
            </div>
            <label className='settingsPageInputLabel' htmlFor="newPassword">New Password</label>
            <div className='settingsPageInputWrapper'>
              <input
                className='settingsPageInput'
                id="newPassword"
                type={this.state.showNewPassword ? 'text' : 'password'}
                value={this.state.newPassword}
                onChange={(e) => this.setState({newPassword: e.target.value})}
              />
              <button className='settingsPageInputShowButton' onClick={() => this.setState({showNewPassword: !this.state.showNewPassword})}>
                <FontAwesomeIcon icon={this.state.showNewPassword ? faEyeSlash : faEye} />
              </button>
            </div>
            <label className='settingsPageInputLabel' htmlFor="confirmNewPassword">Confirm New Password</label>
            <div className='settingsPageInputWrapper'>
              <input
                className='settingsPageInput'
                id="confirmNewPassword"
                type={this.state.showConfirmPassword ? 'text' : 'password'}
                value={this.state.confirmNewPassword}
                onChange={(e) => this.setState({confirmNewPassword: e.target.value})}
              />
              <button className='settingsPageInputShowButton' onClick={() => this.setState({showConfirmPassword: !this.state.showConfirmPassword})}>
                <FontAwesomeIcon icon={this.state.showConfirmPassword ? faEyeSlash : faEye} />
              </button>
            </div>
            {/* {true ? */}
            {this.state.changePasswordLoading ?
              <div className='settingsSubmissionSpinnerContainer'>
                <MDSpinner
                  size={30}
                  singleColor='#0b335d'
                />
              </div>
            :
              <button className='settingsFeedbackSubmitButton primaryButton' onClick={this.fetchChangePassword}>Change Password</button>
            }
          </div>
        </div>
      )
    } else if (this.state.pageDisplayed === 'ReportBug') {
      return (
        <div className='settingsPageWrapper'>
          <button className='settingsPageBackButton primaryButton' onClick={() => this.setState({pageDisplayed: 'Settings'})}>Back To Account Settings</button>
          <h1 className='settingsPageTitle'>Report Bug/Feedback</h1>
          <p className='settingsPageSubtitle'>Noticed a bug or have something to share with us? Let us know here.</p>
          <div className='settingsPageCardContainer'>
            <label className='settingsFeedbackTitle' htmlFor="userFeedback">Enter Feedback</label>
            <textarea className='settingsFeedbackInput' id="userFeedback" value={this.state.userFeedback} onChange={(e) => this.setState({userFeedback: e.target.value})}/>
            {/* {true ? */}
            {this.state.submitFeedbackLoading ?
              <div className='settingsSubmissionSpinnerContainer'>
                <MDSpinner
                  size={30}
                  singleColor='#0b335d'
                />
              </div>
            :
              <button className='settingsFeedbackSubmitButton primaryButton' onClick={this.fetchSubmitFeedback}>Submit Feedback</button>
            }
          </div>
        </div>
      )
    }
  }
}
