import React, { Component } from 'react'
import './Homepage.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartLine, faCheck, faCircleX, faFlag, faHome, faMessageQuestion, faPenToSquare, faSchool, faScrewdriverWrench, faStopwatch } from '@fortawesome/pro-light-svg-icons'
import DonutChart from '../DonutChart/DonutChart'
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion'
import MDSpinner from 'react-md-spinner'
import NewGradingPage from '../NewGradingPage/NewGradingPage'
import IncompleteBlockPopup from './PreviousCompletionsPopup/IncompleteBlockPopup'
import NewSettingsPage from '../NewSettingsPage/NewSettingsPage'
import ErrorPopup from '../ErrorPopup/ErrorPopup'
import ConfirmationPopup from '../ConfirmationPopup/ConfirmationPopup'
import SuccessPopup from '../SuccessPopup/SuccessPopup'

export default class Homepage extends Component {
    constructor() {
        super()
        this.state = {
            homepageDisplay: 'Home',
            unanswered: true,
            incorrect: false,
            flagged: false,
            questionCount: 25,
            selectedCategories: [],
            categories: [],
            footerHeight: 60,
            graphCutout: this.getGraphCutout(),
            fetchingCategories: true,
            displayIncompleteBlockPopup: false,
            incompleteBlockList: [],
            testOptions: {},
            displayConfirmationPopup: false,
            confirmationPopupOptions: null,
            confirmationPopupLoading: false,
            printReceiptHTML: null,
            displayErrorPopup: false,
            errorPopupTitle: '',
            errorPopupText: '',
            displaySuccessPopup: false,
            successPopupTitle: '',
            successPopupText: ''
        }
    }

    componentDidMount() {
        window.addEventListener('resize', () => {
            this.handleScreenResize()
        })
        // this.setCategories()
        this.fetchCategories()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => {
            this.handleScreenResize()
        })
        // console.log('unmounting homepage')
    }

    handleScreenResize = () => {
        let footerElement = document.getElementById('homepageFooter')
        if (footerElement) {
            this.setState({footerHeight: footerElement.offsetHeight})
        }
        this.setState({graphCutout: this.getGraphCutout()})
    }

    getGraphCutout = () => {
        if (window.innerWidth < 1175 && window.innerWidth > 1000) {
            return 58
        } 
        else if (window.innerWidth < 1000 && window.innerWidth > 750) {
            return 80
        }  
        else if (window.innerWidth < 750) {
            return 58
        } else {
            return 80
        }
    }

    changePage = (newPage) => {
        if (this.props.homepageDisplay !== newPage)
            this.setState({mobileDrawerOpen: false}, () => {
                this.props.setHomepageDisplay(newPage)
            })
    }

    getSidebar = (props) => {
        return (
            <div className='sidebarContainer'>
                <h1 className='sidebarLogoText'>PSYCH<span className='highlightedLogoText'>QB</span></h1>
                <div className='sidebarNavContainer'>
                    <button onClick={() => this.changePage('Home')} className={`sidebarNavButton ${this.props.homepageDisplay === 'Home' ? 'sidebarNavButtonSelected' : ''}`}>
                        <FontAwesomeIcon icon={faHome} className='sidebarNavIcon' />
                        Home
                    </button>
                    <button onClick={() => this.changePage('Report Card')} className={`sidebarNavButton ${this.props.homepageDisplay === 'Report Card' ? 'sidebarNavButtonSelected' : ''}`}>
                        <FontAwesomeIcon icon={faChartLine} className='sidebarNavIcon' />
                        My Grades
                    </button>
                    <button onClick={() => this.changePage('Account Settings')} className={`sidebarNavButton ${this.props.homepageDisplay === 'Account Settings' ? 'sidebarNavButtonSelected' : ''}`}>
                        <FontAwesomeIcon icon={faScrewdriverWrench} className='sidebarNavIcon' />
                        Account Settings
                    </button>
                </div>
            </div>
        )
    }

    getCategoryQuestionCount = (category) => {
        let categoryQuestionCount = category.TotalNumberOfQuestions

        if (this.state.flagged && this.state.incorrect) {
            categoryQuestionCount = category.NumberOfIncorrectMarkedQuestions
        } else if (this.state.flagged && this.state.unanswered) {
            categoryQuestionCount = category.NumberOfUnansweredMarkedQuestions
        } else if (this.state.flagged) {
            categoryQuestionCount = category.NumberOfMarkedQuestions
        } else if (this.state.unanswered) {
            categoryQuestionCount = category.NumberOfUnansweredUnmarkedQuestions + category.NumberOfUnansweredMarkedQuestions
        } else if (this.state.incorrect) {
            categoryQuestionCount = category.NumberOfIncorrectQuestions
        } else {
            categoryQuestionCount = category.TotalNumberOfQuestions
        }

        return categoryQuestionCount
    }

    getFilterQuestionCount = (option) => {

        let questionCount = 0
        switch(option) {
            case 'Unanswered':
                this.state.categories.forEach(category => {
                    if (this.state.selectedCategories.includes(category.CATEGORY)) {
                        if (this.state.flagged) {
                            questionCount += category.NumberOfUnansweredMarkedQuestions
                        } else {
                            questionCount += category.NumberOfUnansweredUnmarkedQuestions + category.NumberOfUnansweredMarkedQuestions
                        }
                    }
                })
                return questionCount
            case 'Incorrect':
                this.state.categories.forEach(category => {
                    if (this.state.selectedCategories.includes(category.CATEGORY)) {
                        if (this.state.flagged) {
                            questionCount += category.NumberOfIncorrectMarkedQuestions
                        } else {
                            questionCount += category.NumberOfIncorrectQuestions
                        }
                    }
                })
                return questionCount
            case 'Marked':
                this.state.categories.forEach(category => {
                    if (this.state.selectedCategories.includes(category.CATEGORY)) {
                        if (this.state.incorrect) {
                            questionCount += category.NumberOfIncorrectMarkedQuestions
                        } else if (this.state.unanswered) {
                            questionCount += category.NumberOfUnansweredMarkedQuestions
                        } else {
                            questionCount += category.NumberOfMarkedQuestions
                        }
                    }
                })
                return questionCount
            default:
                return 0
        }
    }

    fetchCategories = async () => {
		await fetch(`${this.props.route}/listcategories.webapi`, {
			method: 'GET',
			headers: {
				'Token': this.props.userData.Token,
			},
		})
		.then(response => {
			if (response.status === 401) {
				this.props.setAuthenticationError()
				throw new Error('Authentication Error')
			} else {
				return response.json()
			}
		})
		.then(response => {
			// console.log(response)
			this.setState({
                categories: response.lc,
                incompleteBlockList: response.ialist.reverse(),
                selectedCategories: response.lc.map((category) => category.CATEGORY),
                fetchingCategories: false
            }, () => {
                let markedQuestions = response.MarkQuestions !== "" ? JSON.parse(response.MarkQuestions).map((question) => Number(question)): []
                this.props.setMarkedQuestions(markedQuestions)
            })
		})
		.catch(error => {
            this.displayErrorPopupHandler('Error: failed to fetch categories', error)
			console.log('error', error);
			// logFetchError(error, this.props.userProfile, this.props.userData, 'fetchCaseList')
		})
	}

    createTestSection = (props) => {
        return (
            <div className='createTestSectionContainer'>
                <h2 className='createTestSectionHeader'>{props.title}</h2>
                {props.selectAll &&
                    <p className='createTestSectionSelectAll' tabIndex="0" onClick={props.handleSelectAll}>Select All</p>
                }
                {props.deselectAll &&
                    <p className='createTestSectionSelectAll' tabIndex="0" onClick={props.handleDeselectAll}>Deselect All</p>
                }
                {props.subtext &&
                    <p className='createTestSectionSubtext'>{props.subtext}</p>
                }
                <div className='createTestSectionContentSpacer' />
                <div className='createTestSectionOptionsContainer'>
                    {props.type === 'Detailed' ?
                        props.options.map((option, index) => (
                            <button className={`createTestSectionOption ${option.checked ? 'selectedOption' : ''}`} onClick={option.onClick} key={`Detailed Section Option ${index}`}>
                                <div className={`createTestButtonCheckboxWrapper ${option.checked ? 'checkboxWrapperChecked' : ''}`}>
                                    <div className='createTestSectionOptionCheckboxContainer'>
                                        {option.checked && <FontAwesomeIcon icon={faCheck} className='createTestButtonCheckIcon' />}
                                    </div>
                                </div>
                                <div className='createTestButtonContentWrapper'>
                                    <FontAwesomeIcon icon={option.icon} className='createTestButtonIcon' />
                                    <h2 className='createTestButtonName'>{option.title} {props.title === 'Question Filters' ? `(${this.getFilterQuestionCount(option.title)})` : ''}</h2>
                                    <p className='createTestButtonDescription'>{option.description}</p>
                                </div>
                            </button>
                        ))
                    : props.type === 'Simple' ?
                        props.options.map((option, index) => (
                            <button className={`createTestSectionSimpleOption ${this.state.selectedCategories.includes(option.CATEGORY) ? 'selectedOption' : ''}`} onClick={() => props.onClick(index)} key={`Simple Section Option ${index}`}>
                                <div className={`createTestSectionOptionCheckboxContainer ${this.state.selectedCategories.includes(option.CATEGORY) ? 'simpleCheckbox' : ''}`}>
                                    {this.state.selectedCategories.includes(option.CATEGORY) && <FontAwesomeIcon icon={faCheck} className='createTestButtonCheckIcon simpleCheckIcon' />}
                                </div>
                                {`${option.CATEGORY} (${this.getCategoryQuestionCount(option)})`}
                            </button>
                        ))
                    : props.type === 'Input' &&
                        <input className='createTestInput' type="number" min="0" max="50" value={props.inputValue} onChange={(e) => props.onChange(e.target.value)} />
                }
                </div>
            </div>
        )
    }

    updateCategories = (index) => {
        let selectedCategories = this.state.selectedCategories
        if (selectedCategories.includes(this.state.categories[index].CATEGORY)) {
            selectedCategories.splice(selectedCategories.indexOf(this.state.categories[index].CATEGORY), 1)
        } else {
            selectedCategories.push(this.state.categories[index].CATEGORY)
        }
        this.setState({selectedCategories})
    }

    handleStartTest = () => {
		const { selectedCategories, questionCount } = this.state
        const { practiceMode, timedExam } = this.props
        
        let questions = []
        let availableQuestionsAmount = 0
        if (this.state.unanswered) {
            questions.push('unused')
            availableQuestionsAmount = this.getFilterQuestionCount('Unanswered')
        }
        if (this.state.incorrect) {
            questions.push('incorrect')
            availableQuestionsAmount = this.getFilterQuestionCount('Incorrect')
        }
        if (this.state.flagged) {
            questions.push('flagged')
            availableQuestionsAmount = this.getFilterQuestionCount('Marked')
        }
        if (questions.length === 3) {
            questions.push ('all')
        }

        if (availableQuestionsAmount < questionCount && questions.length !== 0) {
            return this.displayErrorPopupHandler('Error: not enough questions available', `You have selected ${questionCount} questions, but only ${availableQuestionsAmount} ${availableQuestionsAmount === 1 ? 'is' : 'are'} available. Please update your filters or reduce your question amount.`)
        }

		const testOptions = {
			selectedCategories,
			isTimed: timedExam,
			isPractice: practiceMode,
			questions,
			numberOfQuestions: questionCount,
            unanswered: this.state.unanswered,
            markQuestions: this.state.flagged,
            incorrectQuestions: this.state.incorrect
		}

        this.setState({testOptions, loadingTestData: true}, () => {
            this.newStartBlockHandler()
        })
		// this.props.setTestOptions(testOptions);
		// this.props.startTest();
	}

    handleTestModeChange = (testMode) => {
		if (testMode === 'timed') {
			this.state.isTimed ? this.setState({isTimed: false}) : this.setState({isTimed: true});
		} else {
			this.state.isPractice ? this.setState({isPractice: false}) : this.setState({isPractice: true});
		}
	}

    setCategories = () => {
        this.setState({categories: this.props.categories, selectedCategories: this.props.categories.map((category) => category.CATEGORY)})
        console.log(this.props.categories)
    }

    toggleUnanswered = () => {
        if (this.state.incorrect) {
            this.setState({incorrect: false, unanswered: true})
        } else {
            this.setState({unanswered: !this.state.unanswered})
        }
    }

    toggleIncorrect = () => {
        if (this.state.unanswered) {
            this.setState({unanswered: false, incorrect: true})
        } else {
            this.setState({incorrect: !this.state.incorrect})
        }
    }

    toggleMarked = () => {
        this.setState({flagged: !this.state.flagged})
    }

    getTotalNumberOfQuestions = () => {
        let total = 0
        this.state.categories.forEach((category) => {
            total += category.TotalNumberOfQuestions
        })
        return total
    }

    getQuestionsCompletedGraphValues = () => {
        let completedPercentage = this.getPercentQuestionsCompleted()
        let incompletePercentage = 100 - completedPercentage

        return [completedPercentage, incompletePercentage]
    }

    getPercentQuestionsCompleted = () => {
        let totalNumberOfQuestions = this.getTotalNumberOfQuestions()

        if (totalNumberOfQuestions === 0)
            return 0

        return Math.round((this.getCompletedNumberOfQuestions() / totalNumberOfQuestions) * 100)
    }

    getCompletedNumberOfQuestions = () => {
        let completedQuestions = 0
        this.state.categories.forEach((category) => {
            completedQuestions += category.CompletedNumberOfQuestions
        })
        return completedQuestions
    }

    getAverageGraphValues = () => {
        let correctPercentage = this.getCorrectQuestionsPercentage()
        let incorrectPercentage = 100 - correctPercentage

        return [correctPercentage, incorrectPercentage]
    }

    getCorrectQuestionsPercentage = () => {
        let numberOfCompletedQuestions = 0
        let numberOfIncorrectQuestions = 0

        this.state.categories.forEach((category) => {
            numberOfCompletedQuestions += category.CompletedNumberOfQuestions
            numberOfIncorrectQuestions += category.NumberOfIncorrectQuestions
        })

        if (numberOfCompletedQuestions === 0) {
            return 100
        }

        return Math.round(((numberOfCompletedQuestions - numberOfIncorrectQuestions) / numberOfCompletedQuestions) * 100)
    }

    confirmIncompleteBlockStart = (optionSelected, incompleteBlockList) => {
        // console.log(optionSelected, incompleteBlockList)
        this.setState({loadingTestData: true})
        fetch(`${this.props.route}/incompletestart.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.props.userData.Token,
                'Content-Type': 'text/plain',
			},
            body: JSON.stringify({
                CurrentStateId: incompleteBlockList[optionSelected].CurrentStateId
            })
		})
        .then((response) => {
            if (response.status === 401) {
				this.props.setAuthenticationError()
				throw new Error('Authentication Error')
			} else {
				return response.json()
			}
        })
        .then((response) => {
            // console.log(response)
            response.QuestionsForBlock = JSON.parse(response.QuestionsForBlock)
            if (response.PreviousInput !== "")
                response.PreviousInput = JSON.parse(response.PreviousInput)
            else
                response.PreviousInput = []
            this.setState({loadingTestData: false})
            this.props.beginExam(response)
        })
        .catch((error) => {
            this.setState({loadingTestData: false})
            this.displayErrorPopupHandler('Error: failed to resume incomplete block', error)
            console.log(error)
        })
    }

    newStartBlockHandler = () => {
        const requestBody = {
            "CustomerId": this.props.userData.CustomerId,
            "NumberOfRequestedQuestions": this.state.testOptions.numberOfQuestions,
            "SelectedCategories": this.state.testOptions.selectedCategories,
            "UnansweredQuestions": this.state.testOptions.unanswered,
            "MarkQuestions": this.state.testOptions.markQuestions,
            "IncorrectQuestions": this.state.testOptions.incorrectQuestions,
        }

        // console.log(requestBody)

        fetch(`${this.props.route}/startblock.webapi`, {
            method: 'POST',
            headers: {
            'Token': this.props.userData.Token,
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        })
        .then((response) => {
            if (response.status === 401) {
                this.props.setAuthenticationError()
                throw new Error('Authentication Error')
            } else {
                return response.json()
            }
        })
        .then((response) => {
            this.setState({loadingTestData: false})
            // console.log(response)
            this.props.beginExam(response)
        })
        .catch((error) => {
            this.setState({loadingTestData: false})
            this.displayErrorPopupHandler('Error: failed to start block', error)
            console.log(error)
        })
    }

    displayConfirmationPopupHandler = (confirmationPopupOptions) => {
		this.setState({displayConfirmationPopup: true, confirmationPopupOptions})
	}

    printReceipt = () => {
        window.print()
    }

    displayErrorPopupHandler = (errorPopupTitle, errorPopupText) => {
        this.setState({displayErrorPopup: true, errorPopupTitle, errorPopupText})
    }

    displaySuccessPopupHandler = (successPopupTitle, successPopupText) => {
        this.setState({displaySuccessPopup: true, successPopupTitle, successPopupText})
    }

    render() {

        if (this.state.printReceiptHTML !== null) {
			return (
				<div className='printReceiptContainer'>
					<div dangerouslySetInnerHTML={{__html: this.state.printReceiptHTML}} />
					<div className='printReceiptButtonContainer'>
						<button className='printReceiptButton secondaryButton' onClick={() => this.setState({printReceiptHTML: null})}>Cancel</button>
						<button className='printReceiptButton primaryButton' onClick={this.printReceipt}>Print</button>
					</div>
				</div>
			)
		}

        return (
            <div className='appContainer' style={{height: this.props.pageHeight}}>
                {this.state.displayErrorPopup &&
                    <ErrorPopup
                        closeErrorPopup={() => this.setState({displayErrorPopup: false, errorPopupTitle: '', errorPopupText: ''})}
                        errorPopupTitle={this.state.errorPopupTitle}
                        errorPopupText={this.state.errorPopupText}
                    />
                }
                {this.state.displaySuccessPopup &&
                    <SuccessPopup
                        closePopup={() => this.setState({displaySuccessPopup: false, successPopupTitle: '', successPopupText: ''})}
                        popupTitle={this.state.successPopupTitle}
                        popupText={this.state.successPopupText}
                    />   
                }
                <button className='mobileDrawerToggleButton' onClick={() => this.setState({mobileDrawerOpen: true})}>
                    <div className='mobileDrawerBurgerIcon' />
                </button>
                <div className='desktopSidebarContainer'>
                    <this.getSidebar />
                </div>
                <AnimatePresence exitBeforeEnter>
                    {this.state.mobileDrawerOpen &&
                        <motion.div
                            className='mobileDrawerBackdrop'
                            onClick={() => this.setState({mobileDrawerOpen: false})}
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            exit={{opacity: 0}}
                            transition={{duration: 0.2}}
                            key={`MobileDrawerBackdrop`}
                            style={{height: this.props.pageHeight}}
                        />
                    }
                    {this.state.mobileDrawerOpen &&
                        <motion.div
                            className='mobileDrawerContentWrapper'
                            initial={{x: "-100%"}}
                            animate={{x: "0%"}}
                            exit={{x: "-100%"}}
                            transition={{duration: 0.2, ease: "easeInOut"}}
                            key={`MobileDrawerContentWrapper`}
                            style={{height: this.props.pageHeight}}
                        >
                            <this.getSidebar />
                            <button className='mobileDrawerCloseButton' onClick={() => this.setState({mobileDrawerOpen: false})} />
                        </motion.div>
                    }
                </AnimatePresence>
                {this.props.homepageDisplay === 'Home' ?
                    <div className='homepagePageContainer'>
                        <div className='homepageGraphsWrapper'>
                            <div className='homepageTitleContainer'>
                                <FontAwesomeIcon icon={faHome} className='homepageTitleIcon' />
                                <h1 className='homepageTitleText'>Home</h1>
                            </div>
                            {this.state.fetchingCategories ?
                                <div className='graphSpinnerWrapper'>
                                    <MDSpinner
                                        size={60}
                                        singleColor={'#0b335d'}
                                    />
                                </div>
                            :
                                <div className='homepageGraphContainer'>
                                    <div className='homepageGraphWrapper'>
                                        <DonutChart
                                            id={'TestDonutChart'}
                                            data={{
                                                datasets: [{
                                                    data: [...this.getQuestionsCompletedGraphValues()],
                                                    backgroundColor: ['#0b335d', 'white'],
                                                    borderWidth: 0,
                                                    spacing: this.getQuestionsCompletedGraphValues().includes(0) ? 0 : 7
                                                }]
                                            }}
                                            options={{
                                                title:{
                                                    display:false,
                                                },
                                                legend:{
                                                    display:false,
                                                },
                                                cutout: this.state.graphCutout,
                                                // cutout: 55, For 150px column width
                                                animation: {
                                                    animateScale: true
                                                },
                                                events: []
                                            }}
                                        />
                                        <div className='homepageGraphValuesWrapper'>
                                            <h1 className='homepageGraphValue'>{this.getPercentQuestionsCompleted()}%</h1>
                                            <h2 className='homepageGraphSubvalue'>{this.getCompletedNumberOfQuestions()}/{this.getTotalNumberOfQuestions()}</h2>
                                        </div>
                                    </div>
                                    <h1 className='homepageGraphTitle'>Questions Completed</h1>
                                    <p className='homepageGraphDescription'>The total number of unique questions you've completed. Completing more questions will better prepare you for the exam.</p>
                                </div>
                            }
                            {!this.state.fetchingCategories &&
                                <div className='homepageGraphContainer'>
                                    <div className='homepageGraphWrapper'>
                                        <DonutChart
                                            id={'TestDonutChart'}
                                            data={{
                                                datasets: [{
                                                    data: [...this.getAverageGraphValues()],
                                                    backgroundColor: ['#0b335d', 'white'],
                                                    borderWidth: 0,
                                                    spacing: this.getAverageGraphValues().includes(0) ? 0 : 7
                                                }]
                                            }}
                                            options={{
                                                title:{
                                                    display:false,
                                                },
                                                legend:{
                                                    display:false,
                                                },
                                                cutout: this.state.graphCutout,
                                                // cutout: 55, For 150px column width
                                                animation: {
                                                    animateScale: true
                                                },
                                                events: []
                                            }}
                                        />
                                        <div className='homepageGraphValuesWrapper'>
                                            <h1 className='homepageGraphValue'>{this.getCorrectQuestionsPercentage()}%</h1>
                                        </div>
                                    </div>
                                    <h1 className='homepageGraphTitle'>Average Grade</h1>
                                    <p className='homepageGraphDescription'>Your average grade across all questions you have completed. Improving your average grade will give you more confidence for the exam.</p>
                                </div>
                            }
                        </div>
                        <div className='createTestContainer' style={{paddingBottom: `${this.state.footerHeight}px`}}>
                            <div className='homepageTitleContainer'>
                                <FontAwesomeIcon icon={faPenToSquare} className='homepageTitleIcon' />
                                <h1 className='homepageTitleText'>Create Test</h1>
                            </div>
                            <this.createTestSection
                                title='Test Mode'
                                type='Detailed'
                                options={
                                    [
                                        {
                                            icon: faSchool,
                                            title: `Practice Mode`,
                                            description: 'View the answers to your questions right after you submit them',
                                            checked: this.props.practiceMode,
                                            onClick: this.props.togglePracticeMode
                                        },
                                        {
                                            icon: faStopwatch,
                                            title: `Timed Exam`,
                                            description: 'The real exam will be timed, so turning this on will better prepare you for the exam',
                                            checked: this.props.timedExam,
                                            onClick: this.props.toggleTimedExam
                                        }
                                    ]
                                }
                            />
                            <this.createTestSection
                                title='Question Filters'
                                subtext=''
                                type='Detailed'
                                // selectAll={!this.state.unanswered || !this.state.incorrect || !this.state.flagged}
                                // deselectAll={this.state.unanswered && this.state.incorrect && this.state.flagged}
                                // handleSelectAll={() => this.setState({unanswered: true, incorrect: true, flagged: true})}
                                // handleDeselectAll={() => this.setState({unanswered: false, incorrect: false, flagged: false})}
                                options={
                                    [
                                        {
                                            icon: faMessageQuestion,
                                            title: `Unanswered`,
                                            description: 'Include only questions that you haven\'t previously answered.',
                                            checked: this.state.unanswered,
                                            onClick: this.toggleUnanswered
                                        },
                                        {
                                            icon: faCircleX,
                                            title: `Incorrect`,
                                            description: 'Include only questions that you\'ve previously gotten incorrect',
                                            checked: this.state.incorrect,
                                            onClick: this.toggleIncorrect
                                        },
                                        {
                                            icon: faFlag,
                                            title: `Marked`,
                                            description: 'Include only questions that you have previously marked',
                                            checked: this.state.flagged,
                                            onClick: this.toggleMarked
                                        }
                                    ]
                                }
                            />
                            <this.createTestSection
                                title='Number of Questions'
                                type='Input'
                                inputValue={this.state.questionCount}
                                subtext='The maximum questions per block is 50'
                                onChange={(newValue) => this.setState({questionCount: newValue})}
                            />
                            <this.createTestSection
                                title='Categories'
                                selectAll={this.state.selectedCategories.length !== this.state.categories.length}
                                type='Simple'
                                deselectAll={this.state.selectedCategories.length === this.state.categories.length}
                                handleSelectAll={() => this.setState({selectedCategories: this.state.categories.map((category) => category.CATEGORY)})}
                                handleDeselectAll={() => this.setState({selectedCategories: []})}
                                options={this.state.categories}
                                onClick={this.updateCategories}
                            />
                        </div>
                        <div className='homepageFooterContainer' id='homepageFooter'>
                            {!this.state.loadingTestData && <button className='homepageFooterButton secondaryButton' onClick={() => this.setState({displayIncompleteBlockPopup: true})}>Continue Incomplete Block</button>}
                            {!this.state.loadingTestData && <button className='homepageFooterButton primaryButton' onClick={this.handleStartTest}>Begin Test</button>}
                            {this.state.loadingTestData && 
                                <div className='homepageFooterSpinnerWrapper'>
                                    <MDSpinner className='homepageFooterSpinner' size={40} singleColor={'#0b335d'} />
                                </div>
                            }
                        </div>
                        <AnimatePresence exitBeforeEnter>
                            {this.state.displayIncompleteBlockPopup &&
                                <IncompleteBlockPopup
                                    primaryColor={this.props.primaryColor}
                                    userData={this.props.userData}
                                    closePopup={() => this.setState({displayIncompleteBlockPopup: false})}
                                    incompleteBlockList={this.state.incompleteBlockList}
                                    confirmIncompleteBlockStart={this.confirmIncompleteBlockStart}
                                    continueCaseLoading={this.state.loadingTestData}
                                />
                            }
                        </AnimatePresence>
                    </div>
                : this.props.homepageDisplay === 'Account Settings' ?
                    // <div className='accountSettingsPageWrapper'>
                    //     <button onClick={() => this.props.logoutHandler()}>Logout</button>
                    // </div>
                    <NewSettingsPage
                        logoutHandler={() => this.props.logoutHandler()}
                        userData={this.props.userData}
                        updateSelectedTimeZone={this.props.updateSelectedTimeZone}
                        route={this.props.route}
                        displayConfirmationPopupHandler={this.displayConfirmationPopupHandler}
                        setPopupLoading={(isLoading) => this.setState({confirmationPopupLoading: isLoading})}
                        closeConfirmationPopup={() => this.setState({displayConfirmationPopup: false})}
                        setPrintReceiptHTML={(newValue) => this.setState({printReceiptHTML: newValue})}
                        displayErrorPopupHandler={this.displayErrorPopupHandler}
                        displaySuccessPopupHandler={this.displaySuccessPopupHandler}
                    />
                // : this.props.homepageDisplay === 'Report Card'
                :
                    <NewGradingPage
                        userData={this.props.userData}
                        setAuthenticationError={this.props.setAuthenticationError}
                        route={this.props.route}
                        displayErrorPopupHandler={this.displayErrorPopupHandler}
                        displaySuccessPopupHandler={this.displaySuccessPopupHandler}
                    />
                }
                <AnimatePresence exitBeforeEnter>
                    {this.state.displayConfirmationPopup &&
                        <ConfirmationPopup
                            options={this.state.confirmationPopupOptions}
                            popupLoading={this.state.confirmationPopupLoading}
                        />
                    }
                </AnimatePresence>
            </div>
        )
    }
}
