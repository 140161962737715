import React, { Component } from 'react'
//  Previous code  //
import './App.css';
import logFetchError from './Functions/LogFetchError'
import Spinner from 'react-md-spinner'
import UnauthenticatedHandler from './Components/UnauthenticatedHandler/UnauthenticatedHandler'
//  New code  //
import Test from './Components/Test/Test';
import Homepage from './Components/Homepage/Homepage';
import LoginPage from './Components/NewLogin/LoginPage';
import { AnimatePresence } from 'framer-motion/dist/framer-motion';

class App extends Component {
	constructor() {
		super()
		this.state = {
			authenticationError: false,
			userData: {},
			userProfile: {},
			customTimeLimit: 'none',
			networkLag: 'none',
			caseGrades: null,
			caseListHeaderError: '',
			caseListScrollPosition: 0,
			route: '',
			fetchOutstanding: false,
			production: true,
			loginError: '',
			appDisplay: 'login',
			showLoading: false,
			startCaseData: {},
			caseData: {},
			simulationData: {},
			selectedCaseId: 0,
			grading: null,
			selectedCaseReviewLater: null,
			caseListSort: 'id',
			caseListReversed: false,
			showCaseTitle: false,
			showCaseDiagnosis: false,
			testOptions: {},
			markedQuestions: [],
			pageHeight: window.innerHeight,
			primaryColor: '#0B335D',
			homepageDisplay: 'Home',
			practiceMode: false,
			timedExam: false
		}
	}

	componentDidMount() {
		window.addEventListener('resize', () => {
            this.handleScreenResize()
        })
		this.getRoute()
	}

	componentWillUnmount() {
        window.removeEventListener('resize', () => {
            this.handleScreenResize()
        })
    }

	handleScreenResize = () => {
        this.setState({pageHeight: window.innerHeight})
    }

	// Sets the route for the application requests etc. //
	getRoute = () => {
		let route = ''
		if (process.env.REACT_APP_PRODUCTION !== 'false') {
			route = 'https://app.psychqb.com';
			this.setState({ production: true });
		} else {
			route = `http://localhost:${process.env.REACT_APP_NETWORK_PORT ? process.env.REACT_APP_NETWORK_PORT : 80}`
			this.setState({ production: false })
		}
		this.setState({ route })
	}

	formatTimeHandler = (date) => {
		let formattedDate = new Date(date)
		let hours = formattedDate.getUTCHours()
		let minutes = formattedDate.getUTCMinutes()
		let timeString = `${(hours === 0 || hours === 12) ? '12' : hours > 12 ? hours - 12 : hours}:${minutes} ${hours >= 12 ? 'PM' : 'AM'}`
		return timeString
	}

	formatDateHandler = (date) => {
		let formattedDate = new Date(date)
		let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
		let dateString = `${months[formattedDate.getUTCMonth()]} ${formattedDate.getUTCDate()}, ${formattedDate.getUTCFullYear()}, ${this.formatTimeHandler(date)}`
		return dateString
	}

	updateAccountDetails = (user) => {
		let userProfile = {
			firstName: user.CustomerFirstName,
			lastName: user.CustomerLastName,
			userName: user.CustomerUserName,
			subscriptionEndDate: this.formatDateHandler(`${user.LicenseExpiration}Z`),
			accountCreationDate: this.formatDateHandler(`${user.DateCustCreated}Z`),
			demo: user.Demo,
			caseCompletionLimit: user.LimitFiveCases,
			selectedTimeZone: user.TimeZone === "" ? "-7" : user.TimeZone
		}
		//Current default offset is -6
		this.setState({ userProfile, showLoading: user.LoadingPopUp ? true : false })
	}

	updateSelectedTimeZone = (value) => {
		let initialTimeZone = this.state.userData.TimeZone
		let userData = this.state.userData
		userData.TimeZone = value
		this.setState({ userData, fetchOutstanding: true })
		fetch(`${this.state.route}/updatetimezone.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.state.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				CustomerId: this.state.userData.CustomerId,
				timeZone: value
			})
		})
			.then((response) => {
				this.setState({ fetchOutstanding: false })
				if (response.status === 401) {
					this.setState({ authenticationError: true })
					throw new Error('Authentication Error')
				}
			})
			.catch((error) => {
				let userData = this.state.userData
				userData.TimeZone = initialTimeZone
				this.setState({ userData, fetchOutstanding: false })
				logFetchError(error, this.state.userData, this.state.userData, 'updateSelectedTimeZone')
			})
	}

	viewCaseGrades = (id, reviewLater, diagnosis) => {
		this.setState({
			grading: null,
			appDisplay: 'grading',
			caseGrades: id,
			simulationData: null,
			reviewLater: reviewLater,
			selectedCaseDiagnosis: diagnosis,
		});
	}

	displayGrading = (grades) => {
		let reviewLater = this.state.selectedCaseReviewLater
		let diagnosis = this.state.simulationData.DIAGNOSIS
		this.setState({
			grading: null,
			appDisplay: 'grading',
			caseGrades: this.state.caseData.ID,
			simulationData: null,
			reviewLater: reviewLater,
			selectedCaseDiagnosis: diagnosis,
		})
	}

	//  Set the test options from the create test page  //
	setTestOptions = (testOptions) => {
		this.setState({testOptions: testOptions})
	}

	//  Start the test  //
	startTest = () => {
		this.setState({appDisplay: 'test'});
	}

	endBlockHandler = () => {
		this.setState({appDisplay: 'homepage'})
	}

	updateUserData = (loginData) => {
		this.setState({
			userData: loginData.userData,
			userProfile: loginData.userProfile,
			appDisplay: 'homepage'
		})
	}

	logoutHandler = () => {
		this.setState({
			userData: {},
			userProfile: {},
			appDisplay: 'login',
			homepageDisplay: 'Home',
			timedExam: false,
			practiceMode: false
		})
	}

	setMarkedQuestions = (markedQuestions) => {
		// console.log(markedQuestions)
		this.setState({ markedQuestions })
	}

	displayExamGrade = () => {
		this.setState({ appDisplay: 'homepage', homepageDisplay: 'Report Card'})
	}

	pauseBlockHandler = () => {
		this.setState({ appDisplay: 'homepage'})
	}

	beginExam = (response) => {

		// Format test details
		let testDetails = response

		this.setState({testDetails, appDisplay: 'test'})
	}

	render() {
		return (
			<div className="App" style={{height: this.state.pageHeight}}>
				{this.state.appDisplay === 'homepage' ?
					<Homepage
						updateAccountDetails={this.updateAccountDetails}
						setAuthenticationError={() => this.setState({ authenticationError: true })}
						userData={this.state.userData}
						userProfile={this.state.userProfile}
						timedExam={this.state.timedExam}
						practiceMode={this.state.practiceMode}
						toggleTimedExam={() => this.setState({ timedExam: !this.state.timedExam })}
						togglePracticeMode={() => this.setState({ practiceMode: !this.state.practiceMode })}
						accountSettingsClick={() => this.setState({ appDisplay: 'accountSettings' })}
						route={this.state.route}
						setTestOptions={this.setTestOptions}
						startTest={this.startTest}
						markedQuestions={this.state.markedQuestions}
						setMarkedQuestions={this.setMarkedQuestions}
						logoutHandler={this.logoutHandler}
						pageHeight={this.state.pageHeight}
						homepageDisplay={this.state.homepageDisplay}
						setHomepageDisplay={(homepageDisplay) => this.setState({ homepageDisplay })}
						primaryColor={this.state.primaryColor}
						beginExam={this.beginExam}
						updateSelectedTimeZone={this.updateSelectedTimeZone}
					/>
				: this.state.appDisplay === 'test' ?
					//  Test  //
					<Test 
						testOptions={this.state.testOptions}
						userData={this.state.userData}
						route={this.state.route}
						endBlock={this.endBlockHandler}
						pauseBlockHandler={this.pauseBlockHandler}
						markedQuestions={this.state.markedQuestions}
						setMarkedQuestions={this.setMarkedQuestions}
						setAuthenticationError={() => this.setState({ authenticationError: true })}
						displayExamGrade={this.displayExamGrade}
						testDetails={this.state.testDetails}
						practiceMode={this.state.practiceMode}
						timedExam={this.state.timedExam}
					/>
				:
					<LoginPage
						handleLogin={this.updateUserData}
						route={this.state.route}
						primaryColor={this.state.primaryColor}
						pageHeight={this.state.pageHeight}
					/>
					// <Login
					// 	production={this.state.production}
					// 	loginError={this.state.loginError}
					// 	route={this.state.route}
					// 	updateUserData={this.updateUserData}
					// />
				}
				{(this.state.showLoading && this.state.fetchOutstanding) &&
					<div className='loadingPopupWrapper'>
						<div className='loadingPopupBackdrop' />
						<div className='loadingPopupContainer'>
							<Spinner size={40} singleColor={'#1c4670'} />
							<p className='loadingPopupText'>Loading...</p>
						</div>
					</div>
				}
				<AnimatePresence exitBeforeEnter>
					{this.state.authenticationError &&
						<UnauthenticatedHandler />
					}
				</AnimatePresence>
			</div>
		);
	}
}

export default App;
